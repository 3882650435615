/** @jsx jsx */
import React, { useState, useEffect, useRef } from 'react';
import { jsx, Box, Container, Flex, Grid, Heading, Input } from 'theme-ui';
import GalleryItem from '../sections/gallerySection/GalleryItem';
import Icon from "./Icon";

const IntegrationsSearch = ({ sectionContent }) => {
  const { galleryItems, backgroundColor, heading } = sectionContent;
  const ref = useRef();
  const [data, setData] = useState(galleryItems);
  const [searchVal, setSearchVal] = useState('');
  const [newData, setNewData] = useState('');
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("All");
  const dropDownList = ['All', 'Orchestrators', 'Data Sources', 'Data Catalogs', 'Data Integrations', 'Plugin', 'Utilities'];

  const filterItem = (searchParam, selectedParam) => {
    const arr = [];
    data.map((item) => {
      if (selectedParam !== 'All') {
        if (item.category === selectedParam) {
          arr.push(item);
          const filteredData = arr.filter(newItem => newItem?.title.toLowerCase().includes(`${searchParam.toLowerCase()}`));
          setNewData(filteredData);
        }
      } else {
        arr.push(item);
        const filteredData = arr.filter(newItem => newItem?.title.toLowerCase().includes(`${searchParam.toLowerCase()}`));
        setNewData(filteredData);
      }
      return null; 
    });
  };


  useEffect(() => {
    filterItem(searchVal, selectedValue);
  }, [searchVal, selectedValue]);

  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [open]);

  return (
    <Flex sx={{ padding: "50px 0", backgroundColor: `${backgroundColor}` }}>
      <Container sx={{padding: ["0", null, null, "20px"]}}>
        {heading && (
          <Heading
            as="h2"
            variant="extraBold"
            marginBottom="18px"
            sx={{ textAlign: "center", padding: ['50px 0'] }}
          >
            {heading}
          </Heading>
        )}

        <Flex sx={{ justifyContent: 'space-between', flexDirection: ['column', 'row'], padding: ['25px'], backgroundColor: '#DBDBDB', marginBottom: ['40px'] }}>
          <Flex sx={{ flexDirection: ['column', 'row'], marginBottom: ['20px', '0'] }}>
            <Box sx={{ margin: ['0 0 10px 0', null, '0 50px 0 0'] }}>
              <Box>
                <div className="sort_container">
                  <Flex sx={{ minWidth: ['250px', '200px', null, '250px'], fontWeight: 100, variant: 'forms.select', height: '40px', border: '1px solid #404041 !important', paddingInline: '10px', alignItems: 'center', justifyContent: 'space-between' }}
                    type="button"
                    onClick={() => { setOpen(!open); }}
                  >
                    <Box>
                      {selectedValue}
                    </Box>
                    <Box>
                      {open ? <Icon icon="md-arrow-up" /> : <Icon icon="md-arrow-down" />}
                    </Box>
                  </Flex>

                  {open && (
                    <Box sx={{ width: ['250px', '200px', null, '250px'] }} className="sort_dropdown" ref={ref}>
                      <ul sx={{ borderRadius: '10px', boxShadow: '0px 10px 50px #00000041', backgroundColor: '#fff' }}>
                        {dropDownList.map((item) => (
                          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                          <li
                            sx={{ borderRadius: '10px' }}
                            role="menuitem"
                            key={item}
                            onClick={() => {
                              setSelectedValue(item);
                              setOpen(!open);
                            }}
                          >
                            <Flex
                              sx={{
                                width: "100%",
                                justifyContent: "space-between",
                                fontWeight:
                                  selectedValue === item ? "bold" : "normal",
                              }}
                            >
                              <Box>{item}</Box>{" "}
                              {selectedValue === item ? <Box>&#10003;</Box> : ""}
                            </Flex>
                          </li>
                        ))}
                      </ul>
                    </Box>
                  )}
                </div>
              </Box>
            </Box>

          </Flex>
          <Flex sx={{ position: 'relative' }}>
            <Input placeholder='Search...' sx={{ paddingRight: '30px' }} onChange={(e) => setSearchVal(e.target.value)} />
            <Icon icon='fa-search' className="integration-search-icon" iconColor='#767B88' />
          </Flex>
        </Flex>
        <Grid gap={["10px", 4]} columns={[2, 2, 4]} sx={{px: ["10px", 0, 0]}}>
          {newData && newData?.map((galleryData) => (
              <GalleryItem galleryItem={galleryData} styling={{padding: ["20px"]}}/>
          ))}
        </Grid>
        {newData.length < 0 &&
          <Heading as="h1" sx={{ textAlign: "center", color: '#2b354f' }}>
            No Data Found !
          </Heading>
        }
      </Container>

    </Flex>
  );
};

export default IntegrationsSearch;
