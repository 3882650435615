/** @jsx jsx */
import React from "react";
import { jsx, Box, Flex, Heading, Paragraph } from "theme-ui";
import { GatsbyImage } from "gatsby-plugin-image";
import Link from "../../shared/Link";
import Action from "../../shared/Action";

const GalleryItem = ({ galleryItem, imageOnly, styling }) => {
  const { image, title, description, linkUrl, imageWidth, button } = galleryItem;
  return (
    <Flex
      sx={{
        flexDirection: !imageOnly ? "column" : "row",
        justifyContent: "center",
        alignItems: "center",
        boxShadow:'0px 0px 30px #00000022',
        borderRadius:'15px',
        backgroundColor:'#fff',
        padding:'20px 0',
        ...styling
      }}
    >
      <Link
        to={linkUrl}
        aria-label={linkUrl && `Link to ${title}`}
      >
      <Box sx={{ textAlign:'center'}}>
        <GatsbyImage
          image={image?.gatsbyImageData}
          alt={image?.description || 'grid image'}
          objectFit="contain"
          sx={{ width: imageWidth ? imageWidth : imageOnly ? "172px" : "104px", flex: 1, height: "120px" }}
        />
      </Box>
      {!imageOnly && (
        <Box sx={{ flex: 1 }}>
          {title && (
            <Heading
            as="h4"
            variant="bold"
            sx={{ textAlign: "center", mb: "8px", fontSize: [3] }}
            >
              {title}
            </Heading>
          )}
          {!imageOnly && description && (
            <Paragraph sx={{ textAlign: "center", color:'#2b354f' }}>
              {description.description}
            </Paragraph>
          )}
          {button && (
                <Box sx={{
                    display: 'flex',
                    mb: 4, //margin bottom
                    mt: 2, //margin top
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                    className="hero-action-item-container">
                    <Action
                        {...button}
                    />
                </Box>
            )}
        </Box>
      )}
      </Link>
    </Flex>
  );
};

export default GalleryItem;
